import React, { useEffect, useRef, useState } from "react";
import styles from "styles/OrderInfo.module.css";
import tableStyles from "styles/Table.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { Outlet, useParams, useLocation } from "react-router-dom";
import Button from "components/Button";
import { toast } from "react-toastify";
import getOrderStatus from "components/orderStatus";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import { ReactSVG } from "react-svg";
import { useAuthContext } from "hooks/useAuthContext";
import getDeviceImage from "constants/getDeviceImage";

const OrderInfo = () => {
	let params = useParams();
	const { useGet: useGetBasicData, usePut: useChangeOrderStatus } = useFetch(
		`${PathsAPI.Orders}/${params.id}`
	);
	const {
		response: resBasicData,
		loading,
		hasError,
		request,
	} = useGetBasicData(true);

	const { usePut } = useFetch(`${PathsAPI.Orders}/${params.id}/locker`);
	const { request: reqOpenOrderLocker } = usePut();

	const { usePut: useReturnPut } = useFetch(
		`${PathsAPI.Orders}/${params.id}/return`
	);
	const { request: reqReturnOrder } = useReturnPut();

	const { request: reqChangeOrderStatus } = useChangeOrderStatus();

	const { usePost: useRefundOrderPayment } = useFetch(
		`${PathsAPI.OrderRefund}/${params.id}`
	);
	const { request: reqRefundOrderPayment, loading: loadingRefundOrderPayment } =
		useRefundOrderPayment();

	const { useGet: useGetDeviceData } = useFetch(PathsAPI.Devices);
	const {
		request: reqDeviceData,
		response: resDeviceData,
		loading: loadingDeviceData,
	} = useGetDeviceData();
	const [deviceData, setDeviceData] = useState({});
	const [selectedBox, setSelectedBox] = useState();

	const [filterCartList, setFilterCartList] = useState([]);
	const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const location = useLocation();

	const deviceRef = useRef();

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	useEffect(() => {
		if (resBasicData?.device?.id && !resDeviceData) {
			reqDeviceData();
		} else if (resDeviceData) {
			const device = resDeviceData.filter(
				(device) => device._id === resBasicData?.device?.id
			)[0];
			setDeviceData(device?.boxes ? device.boxes : {});
			setFilterCartList(resBasicData.cartList);
		}
	}, [
		reqDeviceData,
		resBasicData,
		loading,
		resDeviceData,
		loadingDeviceData,
		deviceData,
	]);

	const handleLoadDeviceImage = () => {
		try {
			const boxes = deviceRef.current.reactWrapper.querySelectorAll(`rect`);

			boxes.forEach((box) => {
				if (box.id === "") {
					return;
				}

				box.style.fill = "gray";

				if (!deviceData || !resBasicData) {
					return;
				}

				if (!isGlobal && deviceData[box.id].supplier !== user.supplier) {
					box.style.fill = "gray";
					return;
				}

				const busyForThisOrder = resBasicData.statusDelivery.find(
					(statusDeliveryItem) => {
						if (
							statusDeliveryItem.status === "OPEN_BOX" &&
							statusDeliveryItem.boxId === box.id
						) {
							return true;
						} else {
							return false;
						}
					}
				);

				if (busyForThisOrder) {
					box.style.fill = "orange";
					return;
				}

				box.style.fill = deviceData[box.id]?.rule === "BUSY" ? "gray" : "";

				if (box.style.fill === "gray") {
					return;
				}

				box.style.fill = selectedBox === box.id ? "red" : "";

				box.onclick = () => {

					if(box.id.includes("c")) {
						const boxIdNumber = box.id.replace("c", "");
						setSelectedBox(selectedBox === boxIdNumber ? null : boxIdNumber);
						return;
					}

					const oldSelected = box.style.fill === "red";
					box.style.fill = oldSelected ? "" : "red";
					setSelectedBox(oldSelected ? null : box.id);
				};
			});
		} catch (error) {
			console.error(error);
		}
	};

	const handleOpenOrderLocker = async () => {
		const { statusHistory } = resBasicData;

		const status = statusHistory[statusHistory.length - 1]?.status;

		if (status !== "ACCEPTED_TO_DELIVER") {
			return toast.warning(
				<>
					Zmień status zamówienia
					<br />
					<br />
					Wymagany status:
					<br />
					<strong>Przyjęte do dostarczenia</strong>
					<br />
					<br />
					Obecny status:
					<br />
					<strong>{getOrderStatus(resBasicData)?.message}</strong>
				</>
			);
		}

		if (!selectedBox) {
			return toast.warning(`Nie wybrano żadnej skrytki`);
		}

		if (selectedOrderProducts.length === 0) {
			return toast.warning(`Nie wybrano żadnego produktu z zamówienia`);
		}

		const toastId = toast.loading(`Otwieranie skrytki ..`);
		try {
			await reqOpenOrderLocker({ selectedBox, selectedOrderProducts });
			toast.update(toastId, {
				render: `Skrytka została otwarta`,
				type: "success",
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000,
				closeButton: true,
			});

			const newOrderData = await request();

			const allDelivered = newOrderData.cartList
				.map((cartProduct) => {
					const delivered = newOrderData.statusDelivery.find(
						(statusDeliveryItem) => {
							if (
								statusDeliveryItem.status === "OPEN_BOX" &&
								statusDeliveryItem.productsId.includes(cartProduct._id)
							) {
								return true;
							} else {
								return false;
							}
						}
					);

					return Boolean(delivered);
				})
				.every((element) => element === true);

			if (allDelivered) {
				await reqChangeOrderStatus({ status: "DELIVERED" });
				request();
			}
		} catch (error) {
			toast.update(toastId, {
				render: `Wystąpił problem z otwarciem skrytki`,
				type: "error",
				isLoading: false,
				closeOnClick: true,
				closeButton: true,
			});
		}
	};

	const useHandleLoadData = () => {
		request();
		reqDeviceData();
	};

	const calcSummary = (cartList) => {
		let newSummary = 0;
		cartList.forEach((product) => {
			const amount = product.hasOwnProperty("amount") ? product.amount : 1;
			newSummary += amount * product.brutto;
		});
		return newSummary.toFixed(2);
	};

	const handleChangeSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resBasicData.filter((supplier) => {
			const formattedName = supplier?.name?.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setFilterCartList(result);
	};

	const handleChangeCheckbox = (e) => {
		const checked = e.target.checked;
		const productId = e.target.name;
		setSelectedOrderProducts((prevValues) => {
			let tempPrevValues = [...prevValues];

			if (checked) {
				return [...tempPrevValues, productId];
			} else {
				const removeProductIndex = tempPrevValues.indexOf(productId);
				tempPrevValues.splice(removeProductIndex, 1);
				return tempPrevValues;
			}
		});
	};

	const checkCartProductCondition = (productId) => {
		const isDelivered = resBasicData.statusDelivery.find(
			(statusDeliveryItem) => {
				if (
					statusDeliveryItem.status === "OPEN_BOX" &&
					statusDeliveryItem.productsId.includes(productId)
				) {
					return true;
				} else {
					return false;
				}
			}
		);

		if (
			!isGlobal &&
			resBasicData.cartList.find((product) => product._id === productId)
				?.supplier !== user.supplier
		) {
			return { value: isDelivered, permanent: true };
		}

		if (isDelivered) {
			return { value: true, permanent: true };
		}

		if (selectedOrderProducts.includes(productId)) {
			return { value: true, permanent: false };
		} else {
			return { value: false, permanent: false };
		}
	};

	const filterCartListBySupplier = (cartList) => {
		if (isGlobal) {
			return cartList;
		}

		return cartList.filter((product) => product.supplier === user.supplier);
	};

	const handleRefundOrderPayment = async () => {
		const toastId = toast.loading("Wykonywanie zwrotu ..");
		try {
			const { message } = await reqRefundOrderPayment();

			request();

			toast.success(message);
		} catch (error) {
			toast.error(typeof error === "string" ? error : "Nieznany błąd.");
		}
		toast.dismiss(toastId);
	};

	const handleReturnOrder = async () => {
		const toastId = toast.loading(`Wykonywanie zwrotu ..`);
		try {
			await reqReturnOrder();
			toast.update(toastId, {
				render: `Skrytki zostały otwarte`,
				type: "success",
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000,
				closeButton: true,
			});

			request();
			reqDeviceData();
		} catch (error) {
			toast.update(toastId, {
				render: `Wystąpił problem z otwarciem skrytek`,
				type: "error",
				isLoading: false,
				closeOnClick: true,
				closeButton: true,
			});
		}
	};

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Szczegóły zamówienia</h2>
					<div className={styles.headerButtons}>
						<NavButtonLink
							to="change-status"
							disabled={getOrderStatus(resBasicData)?.refund}
						>
							Zmień status zamówienia
						</NavButtonLink>
						<Button
							loading={loading}
							error={hasError}
							onClick={useHandleLoadData}
						>
							Odśwież
						</Button>
					</div>
				</div>
				<div className={styles.spaceAround}>
					<div>
						<table className={tableStyles.table}>
							<tbody>
								<tr style={{ fontWeight: "bold" }}>
									<td>Identyfikator zamówienia</td>
									<td>{resBasicData?._id || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Status zamówienia</td>
									<th>
										{resBasicData
											? getOrderStatus(resBasicData)?.message
											: "Ładowanie ..."}
									</th>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Czas dostawy</td>
									<td>
										{resBasicData
											? new Date(resBasicData?.deliveryTime).toLocaleString()
											: "Ładowanie ..."}
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>
										Identyfikator urządzenia
									</td>
									<td>{resBasicData?.device?.id || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Położenie urządzenia</td>
									<td>{resBasicData?.device?.location || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Uwagi do zamówienia</td>
									<td>
										<b>
											{resBasicData
												? resBasicData?.comment || "-"
												: "Ładowanie ..."}
										</b>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Data utworzenia</td>
									<td>
										{resBasicData
											? new Date(resBasicData?.createdAt).toLocaleString()
											: "Ładowanie ..."}
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Data aktualizacji</td>
									<td>
										{resBasicData
											? new Date(resBasicData?.updatedAt).toLocaleString()
											: "Ładowanie ..."}
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className={styles.tablesContainer}>
						<table className={tableStyles.table}>
							<tbody>
								<tr style={{ fontWeight: "bold" }}>
									<td>Identyfikator użytkownika</td>
									<td>{resBasicData?.user || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Imię</td>
									<td>
										{resBasicData?.userData?.details?.fname || "Ładowanie ..."}
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Nazwisko</td>
									<td>
										{resBasicData?.userData?.details?.lname || "Ładowanie ..."}
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Numer telefonu</td>
									<td>
										{resBasicData?.userData?.details?.phone || "Ładowanie ..."}
									</td>
								</tr>
							</tbody>
						</table>

						<table className={tableStyles.table}>
							<tbody>
								{resBasicData?.cartList && (
									<>
										<tr style={{ fontWeight: "bold" }}>
											<td>Nazwa produktu</td>
											<td>Uwagi</td>
											<td>Ilość</td>
											<td>Cena brutto</td>
										</tr>
										{filterCartListBySupplier(resBasicData.cartList).map(
											(item, index) => (
												<tr key={index}>
													<td>{item.name}</td>
													<td>{item?.note || "-"}</td>
													<td>{item.amount || 1}</td>
													<td>{item.brutto} zł</td>
												</tr>
											)
										)}
										<tr
											style={{
												background: "var(--primary)",
												color: "#fff",
												fontWeight: "bold",
											}}
										>
											<td colSpan={3}>Razem</td>
											<td>
												{calcSummary(
													filterCartListBySupplier(resBasicData.cartList)
												)}{" "}
												zł
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Urządzenie</h2>
					<div className={styles.headerButtons}>
						<Button
							onClick={handleRefundOrderPayment}
							disabled={
								!getOrderStatus(resBasicData)?.declined ||
								loadingRefundOrderPayment
							}
						>
							Zwróć środki
						</Button>
						{!getOrderStatus(resBasicData)?.return && (
							<Button
								onClick={handleOpenOrderLocker}
								disabled={
									getOrderStatus(resBasicData)?.declined ||
									getOrderStatus(resBasicData)?.refund
								}
							>
								Włóż zamówienie
							</Button>
						)}
						{getOrderStatus(resBasicData)?.return && (
							<Button
								onClick={handleReturnOrder}
								disabled={getOrderStatus(resBasicData)?.returned}
							>
								Odbierz zamówienie
							</Button>
						)}
					</div>
				</div>

				<fieldset>
					<legend>Wyszukiwarka produktów</legend>
					<input
						type="text"
						placeholder="Wpisz szukaną frazę.."
						onChange={handleChangeSearchInput}
					/>
				</fieldset>
				<div className={styles.productContainer}>
					{filterCartList &&
						filterCartList.map((product) => (
							<fieldset key={product._id} className={styles.checkbox}>
								<label htmlFor={`product-${product._id}`}>
									{product.name} ({product._id})
								</label>
								<input
									type="checkbox"
									name={product._id}
									id={`product-${product._id}`}
									onChange={handleChangeCheckbox}
									checked={checkCartProductCondition(product._id).value}
									disabled={
										checkCartProductCondition(product._id).permanent ||
										getOrderStatus(resBasicData)?.refund
									}
								/>
							</fieldset>
						))}
				</div>
			</div>

			<div
				className={[styles.panelContainer, styles.deviceContainer].join(" ")}
			>
				{resBasicData && resDeviceData && deviceData && (
					<ReactSVG
						ref={deviceRef}
						src={getDeviceImage(
							resDeviceData.find(
								(device) => device._id === resBasicData?.device?.id
							)?.configuration?.type
						)}
						className={styles.deviceImage}
						alt="DeviceType1"
						afterInjection={handleLoadDeviceImage}
					/>
				)}
			</div>
			<Outlet />
		</div>
	);
};

export default OrderInfo;
