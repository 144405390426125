import React from "react";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Navbar from "components/Navbar";

import Home from "pages/Home";
import Login from "pages/Login";
import Signup from "pages/Signup";
import Logout from "pages/Logout";
import NotFound from "pages/NotFound";

import AdminRoutes from "routes/Admin";
import DelivererRoutes from "routes/Deliverer";

import { useAuthContext } from "hooks/useAuthContext";
import Version from "pages/version";
import packageJson from "../package.json";
import { toast } from "react-toastify";
import PasswordForget from "pages/PasswordForget";
import PasswordReset from "pages/PasswordReset";
import SignupConfirmUser from "pages/SignupConfirmUser";
import PathsAPI from "constants/PathsAPI";

import KioskProductsPage from "pages/KioskProductsPage";
import Layout from "components/Layout";

function App() {
	const { user } = useAuthContext();
	const isAdmin = () => (["ADMIN", "DELIVERER"].includes(user?.role) ? true : false);
	const logged = ![null, false].includes(user);

	const accountRoutes = () => {
		if (user?.role === "ADMIN") {
			return AdminRoutes();
		} else if (user?.role === "DELIVERER") {
			return DelivererRoutes();
		} else {
			window.location.href = process.env.REACT_APP_USER_URL;
		}
	};

	useEffect(() => {
		fetch(PathsAPI.Version).then(async (resp) => {
			const currentVersion = await resp.json();
			if (currentVersion === packageJson.version) {
				return;
			}

			const versionReloadTry = Number(localStorage.getItem("versionReloadTry"));

			if (versionReloadTry < 1) {
				localStorage.setItem("versionReloadTry", versionReloadTry + 1);
				return document.location.reload();
			} else if (versionReloadTry < 2) {
				localStorage.setItem("versionReloadTry", versionReloadTry + 1);
			} else {
				localStorage.removeItem("versionReloadTry");
			}

			toast.error(
				<>
					<b>UWAGA</b>
					<br />
					Wykryto niezgodność wersji aplikacji z serwerem!
					<br />
					Zalecane odświeżenie strony!
				</>,
				{ autoClose: false, closeButton: false, closeOnClick: false, draggable: false }
			);
		});
	}, []);

	const desktopRoutes = () => {
		switch (user) {
			case null:
				return <Route path="*" element={<></>} />;

			case false:
				return (
					<>
						<Route index element={<Home />} />
						<Route path="/dashboard/*" element={<Navigate to="/" />} />
						<Route path="logout" element={<Navigate to="/" />}></Route>
						<Route path="login" element={<Login />} />
						<Route path="signup" element={<Signup />} />
						<Route path="signup/confirm/:token" element={<SignupConfirmUser />} />
						<Route path="password/forget" element={<PasswordForget />} />
						<Route path="password/reset/:token" element={<PasswordReset />} />
						<Route path="/kiosk" element={<KioskProductsPage />} />
					</>
				);

			default:
				return (
					<>
						{accountRoutes()}
						<Route index element={<Navigate to="dashboard" />} />
						<Route path="login" element={<Navigate to="/" />} />
						<Route path="signup" element={<Navigate to="/" />} />
						<Route path="logout" element={<Logout />}></Route>
					</>
				);
		}
	};

	return (
		<BrowserRouter>
			<Suspense fallback={"Ładowanie"}>
				<Routes>
					{logged && !isAdmin() ? (
						<Route path="*" element={<>{(window.location.href = process.env.REACT_APP_USER_URL)}</>} />
					) : (
						<Route path="/" element={logged ? <Navbar /> : <Layout />}>
							{desktopRoutes()}
							<Route path="/version" element={<Version />} />
							<Route path="*" element={<NotFound />} />
						</Route>
					)}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
