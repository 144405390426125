import getOrderStatus from "components/orderStatus";

const orderTable = {
	_id: {
		name: "Identyfikator zamówienia",
		mobile: false,
	},
	suppliers: {
		name: "Dostawcy",
		parser: (suppliersId, { suppliersList }) => {
			return suppliersId.map((supplierId) => suppliersList?.find((supplier) => supplier._id === supplierId)?.name).join(", ") || "Błąd";
		},
		hideColumn: ({ supplier }) => {
			return !["GLOBAL"].includes(supplier);
		},
		mobile: false,
	},
	"userData.details.fname": {
		name: "Imię",
		hideColumn: ({ mode }) => {
			return !(mode === "export");
		},
	},
	"userData.details.lname": {
		name: "Nazwisko",
		hideColumn: ({ mode }) => {
			return !(mode === "export");
		},
	},
	"userData.details.phone": {
		name: "Telefon",
		hideColumn: ({ mode }) => {
			return !(mode === "export");
		},
	},
	deliveryTime: {
		name: "Najlepiej dostarczyć do",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	device: {
		name: "Lokalizacja FoodBox",
		parser: (device) => {
			return device.location;
		},
	},
	cartList: {
		name: "Łączny koszt brutto",
		parser: (cartList) => {
			let newSummary = 0;
			cartList.forEach((product) => {
				const amount = product.hasOwnProperty("amount") ? product.amount : 1;
				newSummary += amount * product.brutto;
			});
			return `${newSummary.toFixed(2)} zł`;
		},
		mobile: false,
	},
	statusHistory: {
		name: "Status zamówienia",
		parser: (statusHistory) => {
			return getOrderStatus({ statusHistory })?.message || "Nieznany";
		},
	},
	createdAt: {
		name: "Data utworzenia",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
		mobile: false,
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
		mobile: false,
	},
};

export default orderTable;
